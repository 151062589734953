import React, { Suspense } from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Footer from '../components/footer';


const IndexPage = () => {
  return (
    <Layout classmain='homepage'>
      <Seo
        title='Welcome'
        metaDesciption='Anna Bobrovnik'
      />
      <div className='homepage__container container'>
        <div className='intro'>
          <h1 className='intro__title'>Anna Bobrovnik</h1>
          <p className='intro__descr'>UI/UX Designer</p>
          <ul className='intro__menu'>
            <li className='intro__menu--item'>
              <a href='mailto:info@annacreative.io'>info@annacreative.io</a>
            </li>
          </ul>
          <div className='intro__soc'>
            {/*<a href='https://dribbble.com/adbro'>
              <img src='/images/dribbble.svg' alt='Dribbble'/>
            </a>*/}
            <a target="_blank" href='https://www.linkedin.com/in/anna-kandukova-a184a190/'>
              <img src='/images/linkedin.svg' alt='Linkedin'/>
            </a>
            <a target="_blank" href='https://www.facebook.com/akandukova'>
              <img src='/images/facebook.svg' alt='Facebook'/>
            </a>
            <a target="_blank" href='https://www.instagram.com/an_bobrovnik/'>
              <img src='/images/ig.svg' alt='Instagram'/>
            </a>
          </div>
        </div>
        <div className='fbx'>
          <img src='/images/white_logo.svg' alt='AN'/>
        </div>
        <Footer/>
      </div>
    </Layout>
  )
}

export default IndexPage
